import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Masonry from "react-masonry-css"

import Heading from "../../components/Heading"
import Image from "../../components/Image"

import styles from "./inspiration.module.scss"
import RichText from "../../components/RichText"
import SEO from "../../components/SEO"


const Inspiration = ({ data, location }) => {
  const {
    seoDescription,
    heading,
    subheading,
    items,
  } = data.contentfulWorldPage

  // // Offset items because Masonry plugin sorts top to bottom
  // const oddItems = items.filter(item => item % 2 !== 0)
  // const evenItems = items.filter(item => item % 2 === 0)
  // // Combine and reverse items, so most recent are at the top
  // const allItems = oddItems.concat(evenItems).reverse()

  const [itemsReversed, setItemsReversed] = useState([])

  useEffect(() => {
    setItemsReversed([...items].reverse())
  }, [items])

  return(
    <section className={styles.module}>
      <SEO
        title={heading.heading}
        description={seoDescription}
        url={location.pathname}
      />

      <Heading
        tag="h1"
        level="1"
        className={styles.heading}
      >
        {heading.heading}
      </Heading>
      {subheading &&
        <div className={styles.subheading}>
          <RichText
            json={subheading.json}
          />
        </div>
      }
      <Masonry
        breakpointCols={{
          default: 4,
          1439: 3,
          1023: 2,
        }}
        className={styles.grid}
        columnClassName={styles.gridColumn}
      >
        {itemsReversed.map((item, i) => (
          <div key={i}>
            <a href={item.url}>
              <Image
                image={item.desktopImage}
              />
              <span>
                {item.text &&
                  <>{item.text}<br /></>
                }
                <u>Shop</u>
              </span>
            </a>
          </div>
        ))}
      </Masonry>
    </section>
  )
}

export default Inspiration

export const query = graphql`
    query inspirationQuery {
        contentfulWorldPage(pageTitle: {eq: "TM World Inspiration"}) {
            seoDescription
            heading {
                heading
            }
            subheading {
                json
            }
            items {
                ... on ContentfulImage {
                    altText
                    url
                    desktopImage {
                        fluid(maxWidth: 600, quality: 90) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    text
                }
            }
        }
    }
`
